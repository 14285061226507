(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/racing-presentation/assets/javascripts/racing-presentation.js') >= 0) return;  svs.modules.push('/components/racing/racing-presentation/assets/javascripts/racing-presentation.js');
"use strict";


const Fragment = React.Fragment;
const logger = svs.core.log.getLogger('racing_components.racing-presentation');
const gameTypes = svs.racing.common.constants.gameTypes;
const {
  Image
} = svs.content.image_v2;
const {
  ImageTypes,
  getImageOptions
} = svs.racing.common.imageOptions;
const renderReserve = (activeReserve, replacementIsCanceled, replacement) => React.createElement("div", {
  className: "selection-reserve-holder"
}, React.createElement("div", {
  className: "selection-reserve-header"
}, "Reserv:"), React.createElement("div", {
  className: "selection-item ".concat(activeReserve && !replacementIsCanceled ? 'active-reserve' : '', " ").concat(replacementIsCanceled ? 'is-canceled' : '')
}, React.createElement("div", {
  className: "selection-item-text"
}, replacement)));
const renderParticipant = (participant, silksFormattedDate) => {
  const imageOptions = (participant === null || participant === void 0 ? void 0 : participant.silks) && silksFormattedDate && getImageOptions(participant.silks, silksFormattedDate, ImageTypes.CouponSilk);
  return React.createElement("div", {
    className: "driver-info-holder"
  }, React.createElement("div", {
    className: "f-medium"
  }, participant.horseName), React.createElement("div", {
    className: "driver-name-holder"
  }, imageOptions && React.createElement(Image, {
    image: imageOptions
  }), React.createElement("span", {
    className: "driver-name"
  }, participant.driverName)));
};
const getWheelPresentation = _ref => {
  let {
    wheelSelection,
    regularSelection,
    replacement,
    replacementIsCanceled,
    canceledParticipants,
    wheelParticipant,
    silksFormattedDate
  } = _ref;
  let activeReserve = false;
  const selectedWheel = wheelSelection[0];
  const wheelCanceled = canceledParticipants.indexOf(selectedWheel.toString()) > -1;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "game-presentation"
  }, React.createElement("div", {
    className: "game-holder"
  }, React.createElement("div", {
    className: "selection-header f-medium"
  }, "1-2"), React.createElement("div", {
    className: "game-row-holder"
  }, regularSelection.map(selection => {
    const isCanceled = canceledParticipants.indexOf(selection.toString()) > -1;
    if (isCanceled) {
      activeReserve = true;
    }
    return (
      React.createElement(Fragment, {
        key: selection
      }, React.createElement("div", {
        className: "game-row"
      }, React.createElement("div", {
        className: "selection-holder"
      }, React.createElement("div", {
        className: "selection-item ".concat(isCanceled ? 'is-canceled' : '')
      }, React.createElement("div", {
        className: "selection-item-text"
      }, selection)))))
    );
  }), React.createElement("div", {
    className: "break"
  }), React.createElement("div", {
    className: "wheel-presesentation"
  }, React.createElement("div", {
    className: "wheel-header f-uppercase"
  }, "Utg\xE5ngsh\xE4st"), React.createElement("div", {
    className: "wheel-selection"
  }, React.createElement("div", {
    className: "selection-item ".concat(wheelCanceled ? 'is-canceled' : '')
  }, React.createElement("div", {
    className: "selection-item-text"
  }, selectedWheel)), renderParticipant(wheelParticipant, silksFormattedDate)))))), replacement && renderReserve(activeReserve, replacementIsCanceled, replacement));
};

const getPosPresentation = _ref2 => {
  let {
    wager,
    canceledParticipants,
    participants = [],
    silksFormattedDate
  } = _ref2;
  let wagerRow = [];
  if (wager.rxBoards && wager.rxBoards[0] && wager.rxBoards[0]) {
    if (wager.rxBoards[0].rxRaces) {
      wagerRow = wager.rxBoards[0].rxRaces.map(x => x[0]);
    }
    if (wager.rxBoards[0].extras) {
      wagerRow = wager.rxBoards[0].extras;
    }
  }
  const selections = wagerRow;
  const replacement = wager.rxBoards[0].replacement && wager.rxBoards[0].replacement > 0 ? wager.rxBoards[0].replacement : null;
  const replacementIsCanceled = replacement ? canceledParticipants.includes(replacement.toString()) : null;
  let activeReserve = false;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "game-presentation"
  }, selections.map((selection, index) => {
    const isCanceled = canceledParticipants.indexOf(selection.toString()) > -1;
    const participant = participants.filter(currentParticipant => currentParticipant.participantNumber === selection)[0];
    if (isCanceled) {
      activeReserve = true;
    }
    return (
      React.createElement(Fragment, {
        key: selection
      }, React.createElement("div", {
        className: "game-holder"
      }, React.createElement("div", {
        className: "selection-header f-medium"
      }, index + 1), React.createElement("div", {
        className: "game-row"
      }, React.createElement("div", {
        className: "selection-holder",
        key: index
      }, React.createElement("div", {
        className: "selection-item ".concat(isCanceled ? 'is-canceled' : '')
      }, React.createElement("div", {
        className: "selection-item-text"
      }, selection))), participant && renderParticipant(participant, silksFormattedDate))))
    );
  })), replacement && renderReserve(activeReserve, replacementIsCanceled, replacement));
};
const getHighFivePresentation = _ref3 => {
  let {
    regularSelection,
    canceledParticipants,
    replacement,
    activeReserve,
    replacementIsCanceled,
    participants,
    silksFormattedDate
  } = _ref3;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "game-presentation"
  }, regularSelection.map((selection, index) => {
    const isCanceled = canceledParticipants.indexOf(selection.toString()) > -1;
    const participant = participants.filter(currentParticipant => currentParticipant.participantNumber === selection)[0];
    if (isCanceled) {
      activeReserve = true;
    }
    return (
      React.createElement(Fragment, {
        key: selection
      }, React.createElement("div", {
        className: "game-holder"
      }, React.createElement("div", {
        className: "selection-header f-medium"
      }, index + 1), React.createElement("div", {
        className: "game-row"
      }, React.createElement("div", {
        className: "selection-holder"
      }, React.createElement("div", {
        className: "selection-item ".concat(isCanceled ? 'is-canceled' : '')
      }, React.createElement("div", {
        className: "selection-item-text"
      }, selection))), participant && renderParticipant(participant, silksFormattedDate))))
    );
  })), replacement && renderReserve(activeReserve, replacementIsCanceled, replacement), React.createElement("div", {
    className: "any-order-header f-uppercase"
  }, "Alla ordningsf\xF6ljder"));
};

const getNoPosPresentation = _ref4 => {
  let {
    wager,
    canceledParticipants,
    gameType,
    participants = [],
    silksFormattedDate
  } = _ref4;
  const wheelSelection = wager.rxBoards[0].rxRaces[0] || [];
  const regularSelection = wager.rxBoards[0].rxRaces[1] || [];
  let activeReserve = false;

  const replacement = wager.rxBoards[0].replacement && wager.rxBoards[0].replacement > 0 ? wager.rxBoards[0].replacement : null;
  const replacementIsCanceled = replacement ? canceledParticipants.includes(replacement.toString()) : null;
  if (wheelSelection.length > 0) {
    const wheelParticipant = participants.filter(participant => participant.participantNumber === wheelSelection[0])[0];
    return getWheelPresentation({
      wheelSelection,
      regularSelection,
      replacement,
      replacementIsCanceled,
      canceledParticipants,
      wheelParticipant,
      silksFormattedDate
    });
  }
  if (gameType === gameTypes.TOPP5) {
    return getHighFivePresentation({
      regularSelection,
      canceledParticipants,
      replacement,
      activeReserve,
      replacementIsCanceled,
      participants,
      silksFormattedDate
    });
  }
  let firstHeaderTitle;
  let headerTitle = '';
  let showParticipant = false;
  switch (gameType) {
    case gameTypes.PLATS:
      showParticipant = regularSelection.length === 1;
      break;
    case gameTypes.VINNARE:
      showParticipant = regularSelection.length === 1;
      break;
    case gameTypes.TOPP2:
      showParticipant = regularSelection.length === 2;
      firstHeaderTitle = 'plac.';
      headerTitle = '1-2';
      break;
    default:
      return;
  }
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "game-presentation"
  }, React.createElement("div", {
    className: "game-holder"
  }, firstHeaderTitle && React.createElement("div", {
    className: "selection-header f-medium"
  }, headerTitle), React.createElement("div", {
    className: "game-row-holder ".concat(showParticipant ? 'show-participant' : '')
  }, regularSelection.map(selection => {
    const isCanceled = canceledParticipants.indexOf(selection.toString()) > -1;
    const participant = participants.filter(currentParticipant => currentParticipant.participantNumber === selection)[0];
    if (isCanceled) {
      activeReserve = true;
    }
    return (
      React.createElement(Fragment, {
        key: selection
      }, React.createElement("div", {
        className: "game-row"
      }, React.createElement("div", {
        className: "selection-holder"
      }, React.createElement("div", {
        className: "selection-item ".concat(isCanceled ? 'is-canceled' : '')
      }, React.createElement("div", {
        className: "selection-item-text"
      }, selection))), showParticipant && renderParticipant(participant, silksFormattedDate)))
    );
  })))), replacement && renderReserve(activeReserve, replacementIsCanceled, replacement));
};

const getMathPosPresentation = _ref5 => {
  let {
    wager,
    canceledParticipants,
    participants = [],
    silksFormattedDate
  } = _ref5;
  const selections = wager.rxBoards[0].rxRaces || [];
  const replacement = wager.rxBoards[0].replacement && wager.rxBoards[0].replacement > 0 ? wager.rxBoards[0].replacement : null;
  const replacementIsCanceled = replacement ? canceledParticipants.includes(replacement.toString()) : null;
  let activeReserve = false;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "game-presentation"
  }, selections.map((selectionRow, rowIndex) => {
    let participant;
    if (selectionRow.length === 1) {
      participant = participants.filter(participant => participant.participantNumber === selectionRow[0])[0];
    }
    return (
      React.createElement(Fragment, {
        key: rowIndex
      }, React.createElement("div", {
        className: "game-holder"
      }, React.createElement("div", {
        className: "selection-header f-medium"
      }, rowIndex + 1, ":a"), React.createElement("div", {
        className: "game-row"
      }, selectionRow.map((selection, index) => {
        const isCanceled = canceledParticipants.indexOf(selection.toString()) > -1;
        if (isCanceled) {
          activeReserve = true;
        }
        return React.createElement(Fragment, {
          key: selection
        }, React.createElement("div", {
          className: "selection-holder",
          key: index
        }, React.createElement("div", {
          className: "selection-item ".concat(isCanceled ? 'is-canceled' : '')
        }, React.createElement("div", {
          className: "selection-item-text"
        }, selection))), participant && renderParticipant(participant, silksFormattedDate));
      }))))
    );
  })), replacement && renderReserve(activeReserve, replacementIsCanceled, replacement));
};
const RacingPresentation = _ref6 => {
  let {
    fallbackComponent,
    systemType,
    wager,
    race,
    gameType
  } = _ref6;
  const canceledParticipants = [];
  const noOfparticipants = race && race.participants ? race.participants.length : 0;
  race && race.participants && race.participants.forEach(participant => {
    if (participant.canceled === true) {
      canceledParticipants.push(participant.participantNumber.toString());
    }
  });
  switch (systemType) {
    case 'RX_POS':
      return React.createElement("div", null, getPosPresentation({
        wager,
        canceledParticipants,
        gameType,
        participants: race.participants,
        silksFormattedDate: race.meeting.silksFormattedDate
      }));
    case 'RX_NOPOS':
      return React.createElement("div", null, getNoPosPresentation({
        wager,
        canceledParticipants,
        gameType,
        noOfparticipants,
        participants: race.participants,
        silksFormattedDate: race.meeting.silksFormattedDate
      }));
    case 'RX_MATHPOS':
      return React.createElement("div", null, getMathPosPresentation({
        wager,
        canceledParticipants,
        gameType,
        participants: race.participants,
        silksFormattedDate: race.meeting.silksFormattedDate
      }));
    default:
      logger.warn("Unknown SystemType: ".concat(systemType));
      return React.createElement("div", null, fallbackComponent());
  }
};
setGlobal('svs.components.racing.racingPresentation', {
  RacingPresentation
});

 })(window);